import {
  AuthenticateRequest,
  MigrationControllerApiFactory,
} from "src/__generate__";
import { requestAPI } from "./requestApi";

const migrationControllerApiFactory = () =>
  MigrationControllerApiFactory(undefined, "", requestAPI());

export const getMigrationStatus = async () => {
  const response = await migrationControllerApiFactory().getMigrationStatus();
  return response.data;
};

export const migrate = async (params: AuthenticateRequest) => {
  const response = await migrationControllerApiFactory().migrate(params);
  return response.data;
};
