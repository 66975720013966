import { Instance, flow, types } from "mobx-state-tree";
import { GetMigrationStatusResponseStatusEnum } from "src/__generate__";
import { getMigrationStatus } from "src/apis/migration";

const MigrationStatus = types
  .model("Migration", {
    data: types.optional(
      types.frozen<GetMigrationStatusResponseStatusEnum>(),
      GetMigrationStatusResponseStatusEnum.MigrationRequired,
    ),
  })
  .views((self) => {
    return {
      get status() {
        return self.data;
      },
    };
  })
  .actions((self) => {
    const fetch = flow(function* () {
      const response = yield getMigrationStatus();
      self.data = response.status;
    });

    return {
      fetch,
    };
  });

export type IMigrationStatus = Instance<typeof MigrationStatus>;
export default MigrationStatus;
